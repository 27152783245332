<template>
  <div class="map-results__body">
    <component :is="currentType" :results="results.data"></component>
  </div>
</template>

<script>
const types = {
  default: {
    component: () => import('@/components/dump/Map-results/ResultsTable')
  },
  simple: {
    component: () => import('@/components/dump/Map-results/ResultsTableSimplified')
  }
}
export default {
  props: {
    results: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'MapResultsBody',
  computed: {
    types: () => types,
    currentType() {
      return types[this.results.type].component
    }
  }
}
</script>

<style scoped lang="sass">
.map-results
  &__body
    grid-column-start: 2
    +media((margin-bottom: (320: rem(32), 768: rem(48))))
</style>
