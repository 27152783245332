<template>
  <div class="from-footer">
    <simple-btn color="red" type="submit">
      Отправить отзыв
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        color="#fff"
        :height="30"
        :width="30"
        :opacity="1"
        backgroundColor="transparent"
        :z-index="3"
        :is-full-page="false"
      ></loading>
    </simple-btn>
    <form-credentials v-if="isNeedCredentials" />
  </div>
</template>

<script>
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'
import FormCredentials from '@/components/smart/CalculatorRating/Form-credentials'
export default {
  props: {
    isNeedCredentials: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean
    }
  },
  name: 'Form-footer',
  components: { FormCredentials, SimpleBtn, loading }
}
</script>

<style scoped lang="sass">
.from-footer
  display: flex
  +media((align-items: (576: center)))
  +media((flex-direction: (0: column, 576: row)))
  justify-content: flex-start
  +media((grid-column: (768: span 2)))
  margin-top: rem(5)
  .simple-btn
    position: relative
    @extend %16
    flex: 0 0 auto
    font-weight: bold
    min-height: rem(52)
    +media((margin: (0: 0 auto rem(12) 0, 576: 0 rem(20) 0 0)))
    .vld-overlay
      line-height: 0
</style>
