export const externalLinks = [
  {
    id: 0,
    text: 'Нашли <br />ошибку?',
    alt: '!',
    url: 'https://nav.tn.ru/message/',
    img: require('@/assets/img/error.svg')
  },
  {
    id: 2,
    text: 'Код <br />вставки',
    alt: '<>',
    url: 'https://nav.tn.ru/calculators/embed/',
    img: require('@/assets/img/code.svg')
  }
]
