<template>
  <section class="page">
    <div class="skeletons" v-if="isLoading">
      <skeleton v-for="n in 3" :key="`skeleton-${n}`" />
    </div>
    <div class="categories" v-else>
      <Map-card
        v-for="item in cats"
        :key="item.id"
        :itemData="item"
        @selectItem="selectCat(item.id)"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import MapCard from '@/components/dump/MapCard'
import Skeleton from '@/components/dump/Page-elements/Skeleton'

export default {
  name: 'Home',
  components: {
    Skeleton,
    MapCard
  },
  methods: {
    ...mapActions({
      fetchData: 'fetchData'
    }),
    ...mapMutations({
      UPDATE_STORE_PARAM: 'UPDATE_STORE_PARAM'
    }),
    selectCat(id) {
      // this.isCatSelected = true
      // this.selectedCat = id
      // const maps = this.cats.find(p => p.id === id).maps
      // this.$router.push({ name: 'maps', params: { maps, id } })
      this.UPDATE_STORE_PARAM({
        param: 'selectedCat',
        val: id
      })
      this.$router.push('/maps')
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapState({
      cats: state => state.cats,
      isLoading: state => state.pages.home.isLoading
    })
    // selectedCatMaps() {
    //   return this.isCatSelected ? this.cats.find(p => p.id === this.selectedCat).maps : []
    // }
    // items: () => items
  }
}
</script>

<style scoped lang="sass">
.page
  @extend %common-grid
  // background-image: url("~@/assets/img/map.png")
  .categories, .skeletons
    max-width: rem(1632)
    +media((margin: (320: rem(32) 0, 768: rem(64) 0)))
    grid-column-start: 2
    display: grid
    +media((grid-row-gap: (320: rem(8), 768: rem(12), 1200: rem(16), 1500: rem(16))))
    +media((grid-template-columns: (320: 1fr, 768: repeat(3, 1fr))))
    +media((grid-column-gap: (768: rem(12), 1200: rem(18), 1500: rem(12))))
  .skeletons
    width: 100%
    .skeleton
      +media((min-height: (320: rem(160), 768: rem(300), 1200: rem(472))))
      border-radius: rem(12)
      overflow: hidden
</style>
