import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import MapList from '@/views/MapList'
import Map from '@/views/Map'
import MissingPage from '@/views/404'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/maps',
      name: 'Maps',
      component: MapList
    },
    {
      path: '/map',
      name: 'Map',
      component: Map
    },
    {
      path: '/404',
      name: '404',
      component: MissingPage
    },
    {
      path: '*',
      name: 'MissingPage',
      component: MissingPage
    }
  ]
})
