<template>
  <div class="page-footer">
    <div class="page-footer__left">
      <simple-btn to="/maps" simplified>
        <b>Назад</b>
        <template #icon>
          <span class="icon">
            <!--<img src="@/assets/img/button/back&#45;&#45;mini.svg" alt="←" />-->
            <picture>
              <source media="(max-width: 767px)" srcset="@/assets/img/button/back--mini.svg" />
              <img src="@/assets/img/button/back.svg" alt="←" />
            </picture>
          </span>
        </template>
      </simple-btn>
    </div>
    <div :class="descriptionClasses">
      <div class="page-footer__right-text">
        {{ description }}
      </div>
      <div class="page-footer__right-links" v-if="calculators.length">
        <div class="title">Онлайн калькуляторы</div>
        <div class="links">
          <simple-btn
            v-for="calculator in calculators"
            :key="`calculator-${calculator.id}`"
            :url="calculator.url"
            target="_blank"
          >
            {{ calculator.name }}
            <template #icon>
              <span class="icon">
                <img src="@/assets/img/button/calculator.svg" alt="icon" />
              </span>
            </template>
          </simple-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'
export default {
  props: {
    description: {
      type: String,
      default: '',
      required: true
    },
    calculators: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  name: 'MapPageFooter',
  components: { SimpleBtn },
  computed: {
    descriptionClasses() {
      return {
        'page-footer__right': true,
        'page-footer__right--expanded': !this.calculators.length
      }
    }
  }
}
</script>

<style scoped lang="sass">
.page
  &-footer
    grid-column-start: 2
    display: grid
    $t-s: 1fr
    $t-l: 1fr 3fr
    $t-xl: 1fr 5fr
    +media((grid-template-columns: (320: $t-s, 768: $t-l, 1700: $t-xl)))
    +media((margin: (320: rem(32) 0, 768: rem(64) 0)))
    &__right
      display: flex
      +media((flex-direction: (320: column, 1700: row)))
      &-text
        white-space: pre-line
        +media((width: (1700: 35%)))
        +media((max-width: (1700: rem(476))))
        @extend %14
        color: $black_B
        font-weight: normal
        +media((margin: (320: rem(32) 0 rem(35), 768: 0 0 rem(35), 1200: 0 0 rem(72))))
      &--expanded
        .page-footer__right-text
          +media((margin: (320: rem(32) 0 0, 768: 0)))
          width: 100%
          +media((max-width: (1700: rem(1360))))
      &-links
        +media((margin-left: (1700: auto)))
        +media((flex: (1700: 0 0 rem(768))))
        .title
          @extend %18
          color: $black_A
          font-weight: bold
          +media((margin-bottom: (320: rem(18), 768: rem(15))))
        .links
          display: flex
          flex-wrap: wrap
          +media((flex-direction: (320: column, 576: row)))
          // +media((align-items: (576: center)))
          .simple-btn
            +media((max-width: (576: rem(384))))
            +media((width: (576: 50%)))
            border: rem(1) solid $cool_B
</style>
