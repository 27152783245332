<template>
  <div class="item" :class="classes" @click="handler">
    <div class="item__img"><img :src="itemData.url" :alt="itemData.title" /></div>
    <div class="item__title">
      {{ itemData.title }}
    </div>
    <div class="item__rules" v-if="itemData.rules">
      {{ itemData.rules }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {}
    },
    simplified: {
      type: Boolean
    }
  },
  methods: {
    handler() {
      this.$emit('selectItem')
    }
  },
  computed: {
    classes() {
      return {
        'item--simplified': this.simplified
      }
    }
  },
  name: 'MapCard'
}
</script>

<style scoped lang="sass">
.item
  // background: rgba($white, .3)
  box-sizing: border-box
  background: $white
  // max-width: rem(536)
  // border: rem(1) solid rgba($white, .3)
  border-radius: rem(12)
  // backdrop-filter: blur(rem(4))
  display: flex
  flex-direction: column
  // +media((align-items: (320: center, 768: flex-start, 1500: center)))
  +media((align-items: (320: center, 768: flex-start)))
  +media((padding: (320: rem(8) 6.25% rem(20), 768: rem(24) rem(36) rem(40), 1200: rem(24) rem(36) rem(60), 1500: rem(16) rem(68) rem(42))))
  // +media((min-height: (1200: rem(472), 1500: rem(352))))
  +media((min-height: (320: rem(160), 768: rem(300), 1200: rem(472))))
  transition: .3s
  cursor: pointer
  &--simplified
    +media((flex-direction: (320: row, 768: column)))
    +media((min-height: (320: rem(128), 768: rem(200), 1200: rem(368))))
    +media((padding: (320: rem(8) 6.25% rem(8) 0, 768: rem(24) rem(36) rem(40), 1200: rem(48) rem(36) rem(28), 1500: rem(48) rem(50) rem(32))))
    .item
      &__img
        +media((padding-bottom: (320: 0, 768: rem(24), 1200: rem(40))))
        +media((flex: (320: 0 0 rem(80), 360: 0 0 rem(100), 768: none)))
        img
          +media((width: (320: rem(60), 360: rem(80), 1200: rem(140), 1500: rem(144))))
          filter: invert(25%) sepia(100%) saturate(7484%) hue-rotate(347deg) brightness(83%) contrast(119%)
      &__title
        @extend %18
        color: $black_A
        font-weight: bold
        text-align: left
        +media((font-size: (320: rem(14), 1200: rem(18))))
        +media((margin: (320: 0, 768: rem(24) 0 rem(16), 1200: rem(48) 0 rem(16))))

  &__img
    width: 100%
    line-height: 0
    // +media((margin-bottom: (320: rem(8), 768: rem(16), 1500: 0)))
    +media((padding-bottom: (320: rem(8), 768: rem(16))))
    +media((box-shadow: (768: inset 0 rem(-1) 0 $cool_B)))
    img
      display: block
      margin: 0 auto
      +media((width: (320: rem(80), 768: rem(96), 1200: rem(140), 1500: rem(192))))
      // filter: invert(25%) sepia(100%) saturate(7484%) hue-rotate(347deg) brightness(83%) contrast(119%)
      filter: invert(39%) sepia(9%) saturate(10%) hue-rotate(27deg) brightness(86%) contrast(86%)
      // transition: .3s
  &__title, &__rules
    //+media((text-align: (320: center, 768: left, 1500: center)))
    +media((text-align: (320: center, 768: left)))
    text-align: left
    transition: .3s
  &__title
    // +media((padding-top: (768: rem(24), 1200: rem(48), 1500: 0)))
    +media((margin: (320: 0 0 rem(8), 768: rem(24) 0 rem(16), 1200: rem(48) 0 rem(16))))
    // +media((box-shadow: (768: inset 0 rem(1) 0 $cool_B, 1500: none)))
    @extend %24
    // +media((color: (320: $black_A, 768: $red_A, 1200: $black_A)))
    color: $red_A
    font-weight: bold
  &__rules
    @extend %16
    color: $black_A
    font-weight: normal
  @media( any-hover: hover)
    &:hover
      background: $red_A
      // border-color: $red_A
      .item
        &__title, &__rules
          color: $white
        &__img
          img
            filter: invert(1)
</style>
