<template>
  <div class="breadcrumbs">
    <breadcrumb-item v-for="(item, i) in path" :item="item" :key="`link-${i}`" />
  </div>
</template>

<script>
import BreadcrumbItem from '@/components/dump/Page-elements/BreadcrumbItem'

export default {
  props: {
    path: {
      type: Array,
      default: () => []
    }
  },
  name: 'Breadcrumbs',
  components: { BreadcrumbItem }
}
</script>

<style scoped lang="sass">
.breadcrumbs
  +media((margin: (320: rem(24) 0 rem(16), 768: rem(32) 0 rem(16))))
  max-width: 90vw
  overflow-x: auto
  display: flex
  align-items: center
</style>
