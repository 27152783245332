<template>
  <div class="skeleton"></div>
</template>

<script>
export default {
  name: 'Skeleton'
}
</script>

<style scoped lang="sass">
.skeleton
  transform: translateZ(0)
  background: $white
  position: relative
  height: 100%
  width: 100%
  display: block
  overflow: hidden

  &:before
    content: ''
    display: block
    position: absolute
    left: 0
    top: 0
    transform: translateX(-100%)
    height: 100%
    width: 100%
    // border-radius: rem(12)
    background: linear-gradient(to right, $white 0%, $cool_B 50%, $white 100%)
    // background: red
    animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite
@keyframes load
  from
    transform: translateX(-100%)
  to
    transform: translateX(100%)
</style>
