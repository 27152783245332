<template>
  <div class="map-results__header">
    <div class="map-results__header-item">
      <div class="icon">
        <img src="@/assets/img/map-results/pin.svg" alt="pin" />
      </div>
      <div class="content">
        <div class="content__text">Адрес объекта:</div>
        <div class="content__val">{{ data.userAddress }}</div>
      </div>
    </div>
    <div class="map-results__header-item">
      <div class="icon">
        <img src="@/assets/img/map-results/pin-station.svg" alt="pin" />
      </div>
      <div class="content">
        <div class="content__text">{{ featureText }}:</div>
        <div class="content__val">{{ data.mapFeature }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const mapFeatureText = {
  markers: 'Метеостанция',
  polygons: 'Район'
}
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    },
    mapType: {
      type: String,
      default: 'markers',
      required: true
    }
  },
  name: 'MapResultsHeader',
  computed: {
    featureText() {
      return mapFeatureText[this.mapType]
    }
  }
}
</script>

<style scoped lang="sass">
.map-results
  &__header
    grid-column-start: 2
    +media((margin: (320: rem(32) 0 rem(20), 768: rem(32) 0 rem(24))))
    padding-bottom: rem(32)
    box-shadow: inset 0 rem(-1) 0 $black_A
    display: flex
    +media((aling-items: (1600: center)))
    +media((flex-direction: (320: column, 1600: row)))
    &-item
      display: flex
      align-items: center
      &:not(:last-child)
        +media((margin: (320: 0 0 rem(16), 768: 0 0 rem(8), 1600: 0 rem(68) 0 0)))
      .icon
        +media((margin-right: (320: rem(14), 768: rem(22), 1600: rem(8))))
        line-height: 0
      .content
        display: flex
        +media((align-items: (768: center)))
        +media((flex-direction: (320: column, 768: row)))
        &__text
          +media((min-width: (768: rem(120), 1600: rem(115))))
          +media((margin: (320: 0 0 rem(4), 768: 0 rem(72) 0 0, 1600: 0 rem(12) 0 0)))
        &__text, &__val
          @extend %14
          color: $black_A
          font-weight: normal
        &__val
          font-weight: bold
</style>
