import axios from 'axios'

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_API_URL
      : `${location.origin}/action/service_api/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

const ratingEndpoint = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_RATING_URL
      : `${location.origin}/action/service_rating/`,
  headers: {
    'Content-Type': 'application/hal+json',
    Accept: 'application/json'
  }
})

export default {
  fetchData: () => api.get('/content'),
  fetchMap: id => {
    let formData = new FormData()
    formData.append('id', id)
    return api.post('/get_map', formData)
  },
  fetchResults: (mapId, id) => {
    let formData = new FormData()
    formData.append('id', mapId)
    formData.append('row', id)
    return api.post('/get_results', formData)
  },
  fetchPdf: data => {
    let formData = new FormData()
    // let data = {}
    // data.age = 123456
    formData.append('data', JSON.stringify(data))
    // formData.append('data', data)
    return api.post('/pdf', formData, {
      responseType: 'blob'
    })
  }
}

export const sendRating = object => {
  let formData = new FormData()
  formData.append('data', JSON.stringify(object))
  return ratingEndpoint.post('/vote', formData)
  // return new Promise(resolve => {
  //   setTimeout(() => resolve(), 1000)
  // })
}
