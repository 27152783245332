<template>
  <span class="error">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'error'
}
</script>

<style scoped lang="sass">
.error
  color: $red_A
  @extend %12
  position: absolute
  bottom: 0
  left: 0
  transform: translateY(100%)
</style>
