<template>
  <div class="credentials">
    Нажимая на кнопку я подтверждаю согласие с
    <a href="https://nav.tn.ru/privacy/" target="_blank">политикой конфиденциальности</a>
  </div>
</template>

<script>
export default {
  name: 'Form-credentials'
}
</script>

<style scoped lang="sass">
.credentials
  @extend %12
  a
    color: $red_A
    text-decoration: none
</style>
