<template>
  <div class="page" v-if="isMapSelected">
    <loading
      :active.sync="isLoadingShow"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      blur="0"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
    <breadcrumbs :path="breadcrumbsPath" />
    <div class="page-title">{{ currentMap.title }}</div>
    <component
      :is="mapComponent"
      v-if="isMapSelected"
      :tableHeaderData="tableHeaderData"
      :isMapCollapsed="isMapCollapsed"
      :isShowToggle="isShowToggle"
      @requestMapResults="requestMapResults"
      @toggleMapHeight="toggleMapHeight"
      @showToggle="showToggle"
    >
    </component>
    <map-results
      :pdfTitle="currentCat.title"
      :mapTitleToPdf="currentMap.title"
      :rulesToPdf="currentCat.rules"
      :calculatorsToPdf="currentCalculators"
      :pdfDisclaimer="currentMap.description"
      :headerData="tableHeaderData"
      :bodyData="results"
      v-if="isResultsShow"
      :map-type="mapType"
    />
    <MapPageFooter :description="currentMap.description" :calculators="currentCalculators" />
    <calculator-rating />
  </div>
  <loading
    v-else
    :active.sync="isLoadingShow"
    :can-cancel="false"
    color="#E30713"
    :height="145"
    :width="145"
    :opacity="1"
    blur="0"
    backgroundColor="#eeeeee"
    :z-index="3"
    :is-full-page="true"
  ></loading>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import YaMapMarkers from '@/components/smart/YaMapMarkers'
import Breadcrumbs from '@/components/dump/Page-elements/Breadcrumbs'
import MapPageFooter from '@/components/dump/MapPageFooter'
import MapResults from '@/components/dump/Map-results'
import CalculatorRating from '@/components/smart/CalculatorRating'
const mapTypes = {
  markers: () => import('@/components/smart/YaMapMarkers'),
  polygons: () => import('@/components/smart/YaMapPolygons')
}
export default {
  name: 'Map',
  components: { CalculatorRating, MapResults, MapPageFooter, Breadcrumbs, loading },
  data: () => ({
    isMapCollapsed: false,
    isShowToggle: false,
    isCameFromLink: false
  }),
  methods: {
    ...mapActions({
      fetchResults: 'fetchResults',
      fetchDataFromLink: 'fetchDataFromLink'
    }),
    showToggle() {
      this.isShowToggle = true
    },
    toggleMapHeight(val) {
      this.isMapCollapsed = val
    },
    requestMapResults(data) {
      const { id, tableHeaderData } = data
      this.fetchResults({
        mapId: this.selectedMap,
        id,
        tableHeaderData
      })
    }
  },
  mounted() {
    const data = this.$route.query.data
    if (data) {
      try {
        const decodedData = atob(data)
        const { map, cat } = JSON.parse(decodedData)
        this.fetchDataFromLink({ mapId: map, catId: cat })
      } catch {
        this.$router.push({ name: '404', params: { isInvalidLink: true } })
      }
    } else {
      if (!this.isMapSelected) this.$router.push('/maps')
    }
  },
  computed: {
    ...mapGetters({
      getCatById: 'getCatById',
      mapType: 'getMapType'
    }),
    ...mapState({
      selectedMap: state => state.selectedMap,
      selectedCat: state => state.selectedCat,
      results: state => state.results,
      isLoadingShow: state => state.pages.map.isLoading,
      calculators: state => state.calculators,
      tableHeaderData: state => state.tableHeaderData
    }),
    isMapSelected() {
      return this.selectedMap !== null
    },
    currentCat() {
      const { rules, maps, title } = this.getCatById(this.selectedCat)
      return { rules, maps, title }
    },
    currentMap() {
      return this.currentCat.maps.find(p => p.id === this.selectedMap)
    },
    mapComponent() {
      return mapTypes[this.mapType]
    },
    breadcrumbsPath() {
      const home = {
        to: '/',
        text: 'Онлайн карты'
      }
      const maps = {
        to: '/maps',
        text: this.currentCat.rules
      }
      return [home, maps, { text: this.currentMap.title }]
    },
    isResultsShow() {
      return this.results.data?.length
    },
    currentCalculators() {
      return this.calculators.filter(p => {
        return p.maps.includes(this.selectedMap)
      })
    }
  }
}
</script>

<style scoped lang="sass">
.page
  @extend %common-grid
  .breadcrumbs
    grid-column: 2 / 4
  &-title
    @extend %page-title
    grid-column-start: 2
    margin-bottom: rem(46)
  .map-wrapper
    +media((grid-column: (320: '1 / 4', 768: '2 / 3')))
</style>
