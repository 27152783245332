<template>
  <div id="app">
    <section class="main-page" v-if="isFullHeight">
      <Header v-if="!isHideHeaderAndFooter" />
      <router-view />
      <Footer v-if="!isHideHeaderAndFooter" />
    </section>
    <template v-else>
      <Header v-if="!isHideHeaderAndFooter" />
      <router-view />
      <Footer v-if="!isHideHeaderAndFooter" />
    </template>
  </div>
</template>

<script>
import Header from '@/components/dump/Page-elements/Header'
import Footer from '@/components/dump/Page-elements/Footer'
const routeNames = ['MissingPage', '404']
const fullHeightRoutes = ['Home']
export default {
  components: { Footer, Header },
  computed: {
    routeName() {
      return this.$route.name
    },
    isHideHeaderAndFooter() {
      return routeNames.includes(this.routeName)
    },
    isFullHeight() {
      return fullHeightRoutes.includes(this.routeName)
    }
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')
body
  font-family: 'Montserrat', sans-serif
  margin: 0
  background: $cool_B
  overflow-x: hidden
  font-size: 16px
#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
html
  scroll-behavior: smooth
  -moz-scroll-behavior: smooth
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
input[type='number']
  -moz-appearance: textfield
.main-page
  min-height: 100vh
  display: flex
  flex-direction: column
  footer.footer
    margin-top: auto
</style>
