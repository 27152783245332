<template>
  <div class="success-message">
    <div class="success-message__icon">
      <img src="@/assets/img/rating/success.svg" alt="success" />
    </div>
    <div class="success-message__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success-message'
}
</script>

<style scoped lang="sass">
.success-message
  display: flex
  align-items: center
  min-height: rem(136)
  background: $white
  +media((padding: (0: rem(20), 768: rem(44) rem(35))))
  box-sizing: border-box
  &__icon
    margin-right: rem(20)
    line-height: 0
  &__text
    @extend %20
    font-weight: bold
</style>
