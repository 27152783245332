export const sortAscendingArrOfObjects = (arr, param) => arr.sort((a, b) => a[param] - b[param])

export const getFirstElementsByCount = (arr, count) => arr.slice(0, count)

export const numberFormatting = (number, digits) => parseFloat((+number).toFixed(digits))

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const getPlaceMarkCaption = obj => {
  let address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ')

  if (address.trim() === '') {
    address = obj.getAddressLine()
  }
  return address
}

export const zonePointGeometry = {
  geometry: { type: 'Point' },
  properties: { iconCaption: 'Адрес' }
}
export const zonePointPreset = {
  preset: 'islands#blackDotIconWithCaption',
  zIndex: 651,
  iconCaptionMaxWidth: '330'
}

export const zoomOptions = {
  minZoom: 2,
  maxZoom: 20 // 4
}

export const searchControlOptions = {
  // noCentering: true,
  noPlacemark: true,
  placeholderContent: 'Поиск'
}

export const commonMapOptions = {
  center: [102.148546, 61.064605],
  zoom: 3
}
