<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__content-left">
        <Common-link url="https://nav.tn.ru/" target="_blank">
          <template>
            <img src="@/assets/img/logo.svg" alt="logo" />
          </template>
        </Common-link>
      </div>
      <div class="footer__content-middle">
        <ul class="list">
          <li v-for="(link, i) in links" class="list__item" :key="`link-${i}`">
            <CommonLink :url="link.url" target="_blank">
              <template>
                {{ link.title }}
              </template>
            </CommonLink>
          </li>
        </ul>
      </div>
      <div class="footer__content-right">
        <div class="links">
          <Common-link
            :url="link.url"
            :color="'grey'"
            target="_blank"
            v-for="link in externalLinks"
            :key="link.id"
          >
            <template>
              <img :src="link.img" :alt="link.alt" class="icon" />
              <span class="text" v-html="link.text"></span>
            </template>
          </Common-link>
        </div>
        <div class="madeBy">
          <CommonLink url="https://industrialmedia.com.ua/" target="_blank">
            <img src="@/assets/img/logo-im.svg" alt="logo" />
          </CommonLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { externalLinks } from '@/utils/external-links'
import CommonLink from '@/components/dump/Page-elements/Common-link'
const links = [
  {
    title: 'Где купить?',
    url: 'https://shop.tn.ru/teploizoljacija'
  },
  {
    title: 'Заказать профессиональный расчет в ПРЦ',
    url: 'https://nav.tn.ru/services/proektno-raschetnyy-tsentr/form/'
  },
  {
    title: 'Задать вопрос эксперту',
    url: 'https://nav.tn.ru/services/support/'
  }
]

export default {
  name: 'Footer',
  components: { CommonLink },
  computed: {
    links: () => links,
    externalLinks: () => externalLinks
  }
}
</script>

<style scoped lang="sass">
footer.footer
  @extend %common-grid
  background: $light-grey
  +media((padding: (320: rem(32) 0 rem(40), 768: rem(40) 0)))
  .footer
    &__content
      max-width: rem(1632)
      width: 100%
      margin: 0 auto
      grid-column-start: 2
      display: grid
      $t-l: 3fr 6fr rem(210)
      $t-s: 1fr
      +media((grid-template-columns: (320: $t-s, 768: $t-l)))
      &-left
        +media((margin-bottom: (320: rem(32), 768: 0)))
      &-middle
        +media((margin-bottom: (320: rem(32), 768: 0)))
        .list
          @extend %suppress
          list-style: none
          display: inline-flex
          flex-direction: column
          &__item
            &:not(:last-child)
              margin-bottom: rem(16)
      &-right
        display: flex
        flex-direction: column
        .links
          @extend %external-links
          +media((justify-content: (320: flex-start, 768: flex-end)))
          margin-bottom: rem(30)
        .madeBy
          display: inline-flex
</style>
