import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=5993619f&scoped=true&"
import script from "./Breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./Breadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./Breadcrumbs.vue?vue&type=style&index=0&id=5993619f&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5993619f",
  null
  
)

export default component.exports