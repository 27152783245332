<template>
  <section class="missingPage">
    <Header />
    <div class="missingPage__wrapper">
      <div class="missingPage__wrapper-content">
        <div class="missingPage__title">404</div>
        <div class="missingPage__subtitle">
          {{ text }}
        </div>
        <simple-btn color="red" to="/">
          <b>На главную</b>
        </simple-btn>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Header from '@/components/dump/Page-elements/Header'
import Footer from '@/components/dump/Page-elements/Footer'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'

export default {
  name: 'MissingPage',
  components: {
    SimpleBtn,
    Footer,
    Header
  },
  computed: {
    text() {
      return this.$route.params.isInvalidLink
        ? 'К сожалению ссылка по которой вы перешли содержит ошибку.'
        : 'Ой, кажется такой страницы у нас нет. возможно Вы попали сюда случайно?'
    }
  }
}
</script>

<style lang="sass" scoped>
.missingPage
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: space-between

  &__wrapper
    display: grid
    +media((grid-template-columns: (320: rem(15) 10fr rem(15), 768: 1fr 10fr 1fr)))

    &-content
      grid-column-start: 2
      text-align: center

  &__title
    color: $red_A
    +media((font-size: (320: rem(80), 768: rem(150))))
    font-weight: 700
    text-shadow: rem(1) rem(1) 0 $black_A

  &__subtitle
    font-weight: 500
    color: $black_A
    @extend %18
    margin: 0 auto rem(24)
    max-width: rem(400)

  &__btn
    background: $red_A
    color: #fff
    font-style: normal
    font-weight: bold
    font-size: rem(18)
    line-height: rem(22)
    text-decoration: none
    transition: .5s
    width: rem(240)
    height: rem(68)
    border-radius: rem(4)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin: auto auto rem(20)

    &:hover
      background: $red_B
</style>
