import Vue from 'vue'
import App from './App.vue'
import UnsupportedBrowsers from './UnsupportedBrowsers'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

// Internet Explorer 6-11
const isIE = !!document.documentMode
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia
if (!isIE && !isEdge) {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
} else {
  new Vue({
    render: h => h(UnsupportedBrowsers)
  }).$mount('#app')
}
