<template>
  <span :class="wrapperClasses">
    <label :for="`input--${field.name}`" class="form-item__label">
      {{ field.label }}
    </label>
    <textarea
      @input="handle"
      :id="`input--${field.name}`"
      :placeholder="field.placeholder"
      v-if="field.textarea"
      class="form-item__textarea"
    ></textarea>
    <input
      v-else
      @input="handle"
      type="text"
      :id="`input--${field.name}`"
      :placeholder="field.placeholder"
      class="form-item__input"
    />
    <span class="form-item__errors" v-if="isError">
      <slot name="errors"></slot>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    },
    isError: {
      type: Boolean
    }
  },
  methods: {
    handle(e) {
      this.$emit('input', e.target.value)
    }
  },
  name: 'Form-input',
  computed: {
    wrapperClasses() {
      return {
        'form-item': true,
        'form-item--full': this.field.textarea,
        'form-item--error': this.isError
      }
    }
  }
}
</script>

<style scoped lang="sass">
.form-item
  display: grid
  position: relative
  &:after
    content: ""
    position: absolute
    left: 0
    bottom: 0
    height: rem(1)
    background: $red_A
    transition: .5s
    width: 0
    pointer-events: none
  &--error
    &:after
      width: 100%
  &--full
    +media((grid-column: (768: span 2)))
  &__input, &__textarea
    font-family: $main-font
    @extend %16
    color: $black_A
    border: none
    text-overflow: ellipsis
    padding: rem(15) rem(20)
    box-sizing: border-box
    box-shadow: 0 rem(15) rem(45) rem(-27) rgba(0, 0, 0, 0.2)
    outline: none
  &__textarea
    min-height: rem(130)
  &__label
    @extend %14
    margin-bottom: rem(6)
</style>
