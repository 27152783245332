import { render, staticRenderFns } from "./BreadcrumbItem.vue?vue&type=template&id=1f949701&scoped=true&"
import script from "./BreadcrumbItem.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbItem.vue?vue&type=script&lang=js&"
import style0 from "./BreadcrumbItem.vue?vue&type=style&index=0&id=1f949701&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f949701",
  null
  
)

export default component.exports