import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '@/router'
import { isEmpty } from 'ramda'
import { saveAs } from 'file-saver'
import { isSafari } from '@/utils'
import api from '@/api'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
const pages = {
  home: 'home',
  mapList: 'mapList',
  map: 'map'
}
export default new Vuex.Store({
  state: {
    cats: [],
    calculators: [],
    results: {},
    mapData: [],
    selectedCat: null,
    selectedMap: null,
    tableHeaderData: {
      userAddress: null,
      mapFeature: null
    },
    pages: {
      home: {
        isLoading: true
      },
      mapList: {
        isLoading: false
      },
      map: {
        isLoading: false
      }
    }
  },
  mutations: {
    // UPDATE_SELECTED_CAT: (state, id) => {
    //   state.selectedCat = id
    // },
    UPDATE_STORE_PARAM: (state, { param, val }) => {
      state[param] = val
    },
    UPDATE_MAP_DATA: (state, { id, mapData }) => {
      state.selectedMap = id
      if (!isEmpty(state.results)) state.results = {}
      state.mapData = Object.freeze(mapData)
    },
    UPDATE_CONTENT: (state, { maps, calculators }) => {
      state.cats = Object.freeze(maps)
      state.calculators = Object.freeze(calculators)
    },
    UPDATE_LOADING_STATUS: (state, { page, status }) => {
      state.pages[page].isLoading = status
    },
    UPDATE_TABLE_HEADER_DATA: (state, { userAddress, mapFeature }) => {
      state.tableHeaderData.userAddress = userAddress
      state.tableHeaderData.mapFeature = mapFeature
    },
    CLEAR_STATE_MAP: state => {
      const { mapData, results, selectedMap, tableHeaderData } = state
      if (mapData.length) state.mapData = []
      if (!isEmpty(results)) state.results = {}
      if (selectedMap !== null) state.selectedMap = null
      if (tableHeaderData.mapFeature !== null) state.tableHeaderData.mapFeature = null
      if (tableHeaderData.userAddress !== null) state.tableHeaderData.userAddress = null
    }
  },
  getters: {
    getCatById: state => id => {
      return state.cats.find(p => p.id === id)
    },
    getMapType: state => {
      return state.mapData.type
    }
  },
  actions: {
    fetchDataFromLink({ commit, state }, { mapId, catId }) {
      const pushToErrorPage = () => router.push({ name: '404', params: { isInvalidLink: true } })
      commit('CLEAR_STATE_MAP')
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      Promise.all([api.fetchData(), api.fetchMap(mapId)])
        .then(response => {
          const [content, mapData] = response
          commit('UPDATE_CONTENT', content.data)
          if (state.cats.some(cat => cat.id === catId)) {
            const currentCat = state.cats.find(cat => cat.id === catId)
            if (currentCat.maps.some(map => map.id === mapId)) {
              commit('UPDATE_STORE_PARAM', { param: 'selectedCat', val: catId })
              commit('UPDATE_MAP_DATA', {
                id: mapId,
                mapData: mapData.data
              })
              commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
              router.push('/map')
            } else {
              pushToErrorPage()
            }
          } else {
            pushToErrorPage()
          }
        })
        .catch(() => pushToErrorPage())
      // api.fetchData().then(response => {
      //   commit('UPDATE_CONTENT', response.data)
      //   commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
      // })
    },
    fetchData({ commit }) {
      api.fetchData().then(response => {
        commit('UPDATE_CONTENT', response.data)
        commit('UPDATE_LOADING_STATUS', { page: pages.home, status: false })
      })
    },
    fetchMap({ state, commit }, id) {
      // state.pages.mapList.isLoading = true
      commit('UPDATE_LOADING_STATUS', { page: pages.mapList, status: true })
      // console.log(`request map data from API, id: ${id}`)
      api.fetchMap(id).then(response => {
        commit('UPDATE_MAP_DATA', {
          id: id,
          mapData: response.data
        })
        commit('UPDATE_LOADING_STATUS', { page: pages.mapList, status: false })
        router.push('/map')
      })
    },
    fetchResults({ state, commit }, { mapId, id, tableHeaderData }) {
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      commit('UPDATE_TABLE_HEADER_DATA', tableHeaderData)
      api.fetchResults(mapId, id).then(response => {
        // console.log(response)
        commit('UPDATE_STORE_PARAM', {
          param: 'results',
          val: response.data
        })
        commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
      })
    },
    fetchPdf({ state, commit }, data) {
      state.pages.map.isLoading = true
      commit('UPDATE_LOADING_STATUS', { page: pages.map, status: true })
      api.fetchPdf(data).then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        if (isSafari()) {
          const fileName = `${data.mapTitle} | населенный пункт: ${data.city}.pdf`
          saveAs(file, fileName)
        } else {
          window.open(fileURL)
        }
        commit('UPDATE_LOADING_STATUS', { page: pages.map, status: false })
      })
    }
  },
  plugins: [vuexLocal.plugin]
})
