<template>
  <div id="app">
    <div class="browser__message-overlay">
      <div class="browser__message">
        <div class="browser__message-title">
          Извините, для работы с калькулятором вам необходимо использовать более современный
          браузер.
        </div>
        <div class="browser__message-text">
          Ваш текущий браузер не поддерживает все необходимые функции. Если у вас на компьютере нет
          установленной последней версии подходящего браузера, например Google Chrome, Mozilla
          Firefox или Opera
        </div>
        <div class="browser__message-text">вы можете скачать их с официальных сайтов:</div>
        <div class="browser__message-browsers">
          <simple-btn url="https://www.google.com/intl/ru/chrome/" target="_blank">
            <img class="item__img" src="@/assets/img/chrome.svg" alt="chrome" />
            <div class="item__text">Google Chrome</div>
          </simple-btn>
          <simple-btn url="https://www.mozilla.org/ru/firefox/new/" target="_blank">
            <img class="item__img" src="@/assets/img/firefox.svg" alt="firefox" />
            <div class="item__text">FireFox</div>
          </simple-btn>
          <simple-btn url="https://www.opera.com/ru" target="_blank">
            <img class="item__img" src="@/assets/img/opera.svg" alt="opera" />
            <div class="item__text">Opera</div>
          </simple-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'
export default {
  name: 'UnsupportedBrowsers',
  components: { SimpleBtn }
}
</script>

<style lang="sass" scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext,latin-ext')
body
  overflow-x: hidden
  background: $cool_B
  margin: 0
  font-family: $main-font

#app
  text-align: center
  font-family: $main-font

  .container
    max-width: rem(960)

  .browser
    &__message
      margin: auto
      display: block
      background: $white
      width: rem(470)
      text-align: left
      border-radius: rem(5)
      padding: rem(15)
      max-width: 100%
      box-shadow: 0 0 rem(7) 0 rgba(0, 0, 0, 0.16)

      &-title
        font-size: rem(18)
        font-weight: 700

      &-text
        font-size: rem(14)
        padding: rem(20) 0 0
        font-weight: 500

      &-browsers
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        padding-top: rem(20)

        .simple-btn
          background: $cool_B
          border-radius: rem(5)
          width: calc(100% / 3.2)
          height: rem(145)
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          color: $black_A
          .item
            &__img
              max-width: rem(86)
              height: auto

            &__text
              padding-top: rem(15)
              color: $black_A

      &-overlay
        height: 100vh
        align-items: center
        display: flex
</style>
