<template>
  <div class="grade-item">
    <input
      type="radio"
      :value="item.value"
      v-model="computedValue"
      name="calculator-rating"
      class="input"
      :id="`grade--${item.value}`"
    />
    <label :for="`grade--${item.value}`" :class="labelClasses">
      <span class="label__icon">
        <img :src="gradeImage" :alt="item.text" />
      </span>
      <span class="label__text">
        {{ item.text }}
      </span>
      <grade-item-check v-if="currentValue === item.value" :color="currentColor" />
    </label>
  </div>
</template>

<script>
import GradeItemCheck from '@/components/smart/CalculatorRating/GradeItemCheck'
import { colors } from '@/utils/rating'

export default {
  components: { GradeItemCheck },
  props: {
    currentValue: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'GradeItem',
  computed: {
    gradeImage() {
      return require(`@/assets/img/rating/${this.item.value}.svg`)
    },
    labelClasses() {
      return ['label', `label--${this.item.value}`]
    },
    currentColor() {
      return colors[this.item.value]
    },
    computedValue: {
      get() {
        return this.currentValue
      },
      set(grade) {
        this.$emit('updateGrade', grade)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.grade-item
  .label
    cursor: pointer
    transition: .5s
    background: $white
    display: flex
    align-items: center
    +media((justify-content: (0: center, 480: flex-start)))
    +media((min-heght: (768: rem(68))))
    +media((padding: (0: rem(12), 480: rem(12) rem(20), 992: rem(17) rem(25))))
    height: 100%
    box-sizing: border-box
    color: $black_A
    position: relative
    border: rem(1) solid transparent
    &--bad, &--idea
      @media(any-hover: hover)
        &:hover
          border-color: $rating-red
    &--neutral
      @media(any-hover: hover)
        &:hover
          border-color: $rating-yellow
    &--good
      @media(any-hover: hover)
        &:hover
          border-color: $rating-green
    &__icon
      +media((margin-right: (480: rem(25))))
      flex: 0 0 rem(30)
      line-height: 0
      height: rem(30)
      width: rem(30)
      display: flex
      justify-content: center
      align-items: center
      img
        width: 100%
        height: auto
        display: block
    &__text
      @extend %16
      +media((display: (0: none, 480: inline)))
  .input
    position: absolute
    opacity: 0
    display: none
    pointer-events: none
    &:checked
      + .label
          // .check
            opacity: 1
          &--good
            border-color: $rating-green
          &--idea, &--bad
            border-color: $rating-red
          &--neutral
            border-color: $rating-yellow
</style>
